import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { css } from '@emotion/core';
import FullWidthBox from 'components/FullWidthBox';
import SEO from 'components/SEO/SEO';
import ExternalLink from 'components/ExternalLink';
import StyledBox from 'components/StyledBox';

export default function PostPage(props) {
  return <Post {...props} />;
}

function Post({ data: { post }, location }) {
  const { title, content, date, featured_media, categories } = post;
  return (
    <>
      {/* <SEO
        title={`blog: ${title}`}
        description={excerpt}
        image={featured_media.source_url}
        pathname={location.pathname}
        headerImage={featured_media.source_url}
        article
        follow="index,follow"
      /> */}
      <StyledBox>
        <article>
          <FullWidthBox
            pad={{
              top: '120px',
              bottom: '20px',
            }}
            sectionwidth="45rem"
          >
            <h1
              css={css`
                text-align: center;
                margin: 0;
              `}
            >
              {title}
            </h1>
            <StyledBox className="align-c">
              <StyledBox
                css={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
                pad={{ top: 'small', left: 'none', right: 'none' }}
              >
                <h3
                  style={{
                    fontSize: '15px',
                    lineHeight: '24px',
                    fontWeight: '500',
                    letterSpacing: '.0071428571em',
                    margin: '0',
                  }}
                >
                  <span
                    style={{
                      lineHeight: '1.375rem',
                      fontWeight: '500',
                      letterSpacing: '.0071428571em',
                      margin: '0',
                    }}
                  >
                    {date}
                  </span>
                </h3>
              </StyledBox>
              {categories && (
                <StyledBox
                  css={css`
                    padding: 20px 0 0 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 50px;
                  `}
                >
                  <span className="text large">
                    Kategorien:
                    {categories.map((categoryItem, idx) => {
                      return (
                        <span className="text large" key={idx}>
                          {' '}
                          {categoryItem.name}
                        </span>
                      );
                    })}
                  </span>
                </StyledBox>
              )}
            </StyledBox>
            {featured_media && (
              <div style={{ textAlign: 'center' }}>
                <img
                  src={featured_media.source_url}
                  alt={featured_media.alt}
                  title={featured_media.title}
                />
              </div>
            )}
            <StyledBox
              css={css`
                padding: 20px 0 0 0;
              `}
            >
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </StyledBox>
          </FullWidthBox>
        </article>
      </StyledBox>
    </>
  );
}

export const pageQuery = graphql`
  query BlogPost($id: String!) {
    post: wordpressPost(id: { eq: $id }) {
      id
      content
      path
      title
      date
      excerpt
      slug
      featured_media {
        alt_text
        source_url
        title
      }
      categories {
        name
      }
    }
  }
`;
